import PropTypes from "prop-types"
import React from "react"
import "../css/components/contactcard.scss"
import Image from "../components/image"

import Email from "../images/icons/email.svg"
import Twitter from "../images/icons/twitter.svg"
import Discord from "../images/icons/discord.svg"
import Website from "../images/icons/website.svg"

const ContactCard = ({
  animationDelay,
  title,
  pfp,
  social: { email, twitter, discord, website },
}) => (
  <div className="contactcard" style={{animationDelay: `${animationDelay}s`}}>
    <h1>{title}</h1>
    
    <div className="pfp-wrapper">
      <div className="pfp">
        <Image filename={pfp} width={200} height={200} />
      </div>
    </div>
    <div className="social">
      {email && (
        <p>
          <Email />
          <a href={`mailto:${email}?subject=Vitamin%20Games%20Website`}>
            {email}
          </a>
        </p>
      )}
      {twitter && (
        <p>
          <Twitter />
          <a className="twitter" href={`https://twitter.com/${twitter}`} target="_blank">
            @{twitter}
          </a>
        </p>
      )}
      {discord && discord.title && (
        <p>
          <Discord />
          <a className="discord" href={`http://discord.gg/${discord.link}`} target="_blank">
            {discord.title}
          </a>
        </p>
      )}
      {website && (
        <p>
          <Website />
          <a className="discord" href={website} target="_blank">
            Website
          </a>
        </p>
      )}
    </div>
  </div>
)

ContactCard.propTypes = {
  animationDelay: PropTypes.number,
  title: PropTypes.string,
  pfp: PropTypes.string,
  social: {
    email: PropTypes.string,
    twitter: PropTypes.string,
    discord: {
      title: PropTypes.string,
      link: PropTypes.string,
    },
    website: PropTypes.string,
  },
}

ContactCard.defaultProps = {
  animationDelay: 0.15,
  social: {
    discord: {},
  },
}

export default ContactCard
