import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactCard from "../components/contactcard"

import "../css/pages/team.scss"
import CardContainer from "../components/cardcontainer"

const Team = () => (
  <Layout showFooter={false}>
    <SEO title="Team" />
    <div className="main">
      <div className="contact">
        <div className="cards">
          <div>
            <h1 className="section-header">Vitamin Games</h1>
            <CardContainer>
              <ContactCard
                title="Owner / Creator"
                pfp="pfps/pidge.png"
                social={{
                  email: "owner@vitamin.games",
                  twitter: "vg_pidge",
                  discord: {
                    title: "Vitamin Games",
                    link: "projectarrhythmia",
                  },
                }}
              />
              <ContactCard
                animationDelay={0.3}
                title="Lead Artist"
                pfp="pfps/raukisu.jpg"
                social={{
                  email: "raukisu@gmail.com",
                }}
              />
            </CardContainer>
          </div>
          <div>
            <h1 className="section-header">Top Hat Studios</h1>
            <CardContainer>
              <ContactCard
                title="Publisher"
                pfp="pfps/tophat.png"
                social={{
                  email: "pr@tophatentertainment.us",
                  twitter: "TopHatStudiosEN",
                  website: "https://tophat.studio",
                }}
              />
            </CardContainer>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Team
