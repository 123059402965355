import React from "react"
import "../css/components/cardcontainer.scss"

const CardContainer = ({children}) => (
  <div className="cardcontainer">{children}</div>
)

CardContainer.propTypes = {}

CardContainer.defaultProps = {}

export default CardContainer
